<template>
  <div id="jobs" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">LISTA DE PUESTOS</v-row>
      <v-row no-gutters justify="end" class="mr-12 mb-4">
        <v-col
          cols="12"
          lg="6"
          md="5"
          sm="4"
          xs="12"
          class="selectEmpresa"
          align-self="end"
        >
          <v-row class="ml-12">
            <label for="enterprise" class="ml-5 mr-2">Empresa Pagadora</label>
            <select
              name="enterprise"
              id="enterprise"
              v-model="enterprise"
              @change="obtenerEmpresa()"
            >
              <option selected disabled value="0">Selecciona Empresa</option>
              <option
                v-for="enterprise in enterprises"
                :key="enterprise.id"
                v-bind:value="enterprise.id"
              >
                {{ enterprise.razonSocial }}
              </option>
            </select>
          </v-row>
        </v-col>
        <v-col cols="12" lg="6" md="6" sm="5" xs="12">
          <v-card-title>
            <router-link to="/addJob" id="nuevovacante">
              <button class="breakSearchE botonAmarillo">Alta de puesto</button>
            </router-link>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="jobs"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Puestos por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.id }}</td>
                  <td class="text-sm-center">{{ item.nombrePuesto }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="editItem(item)"
                      >fa-edit</v-icon
                    >
                    <v-icon
                      color="#717171"
                      small
                      class="mr-6"
                      @click="viewItem(item)"
                      >fa-eye</v-icon
                    >
                    <v-icon color="red" small @click="temporal(item)"
                      >fa-trash-alt</v-icon
                    ><v-icon
                      color="green"
                      v-if="item.puestoServicioCliente == 1"
                      small
                      class="ml-6"
                      >far fa-check-circle</v-icon
                    >
                    <v-icon v-else color="red" class="ml-6" small
                      >far fa-times-circle</v-icon
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
    <!--Editar item-->
    <v-dialog v-model="edit" width="380">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Edición de datos</span>
        </v-card-title>
        <v-card-text>
          <!--EMPRESA-->
          <v-row>
            <div class="form-group mt-9 mb-2">
              <label for="enterprise" class="ml-2 mr-12">Empresa</label>
              <select
                name="enterprise"
                id="enterprise"
                v-model="enterprise"
                @change="obtenerAreasTrabajos()"
                class="ml-12 mt-n6"
                style="width: 170px !important"
                disabled
              >
                <option selected disabled value="0">Selecciona Empresa</option>
                <option
                  v-for="enterprise in enterprises"
                  :key="enterprise.id"
                  v-bind:value="enterprise.id"
                >
                  {{ enterprise.razonSocial }}
                </option>
              </select>
            </div>
          </v-row>
          <!--AREA A LA QUE REPORTA-->
          <v-row>
            <div class="form-group mb-3">
              <label for="area" class="ml-2">Área a la que reporta</label>
              <select
                name="area"
                id="area"
                class="ml-5 mt-n6"
                style="width: 170px !important"
                v-model="area"
              >
                <option selected disabled value="0">Selecciona área</option>
                <option
                  v-for="are in areas"
                  :key="are.id"
                  v-bind:value="are.id"
                >
                  {{ are.nombreArea }}
                </option>
              </select>
            </div>
          </v-row>
          <!--PUESTO AL QUE REPORTA-->
          <v-row>
            <div class="form-group mb-3">
              <label for="puestoReporta" class="ml-2 mr-3"
                >Puesto al que reporta</label
              >

              <select
                name="enterprise"
                id="enterprise"
                class="ml-1 mt-n6"
                style="width: 170px !important"
                v-model="jobReporta"
              >
                <option value="0">Ninguno</option>
                <option
                  v-for="job in jobs"
                  :key="job.jobs"
                  v-bind:value="job.id"
                >
                  {{ job.nombrePuesto }}
                </option>
              </select>
            </div>
          </v-row>
          <!--NUMERO DE POSICIONES-->
          <v-row>
            <div class="form-group mb-1">
              <label for="posicion" class="ml-2 mr-1">Número de posición</label>
              <select
                name="posicion"
                id="posicion"
                class="ml-5"
                style="width: 170px !important"
                v-model="posicion"
              >
                <option selected disabled value="0">Selecciona Nivel</option>
                <option
                  v-for="level in niveles"
                  :key="level.id"
                  v-bind:value="level.id"
                >
                  {{ level.name }}
                </option>
              </select>
            </div>
          </v-row>
          <!--NOMBRE DEL PUESTO-->
          <v-row>
            <div class="form-group mb-3">
              <label for="puesto" class="ml-2 mr-3">Nombre del puesto</label>
              <input
                type="text"
                name="puesto"
                id="puesto"
                placeholder="Obligatorio"
                class="ml-4 inputs"
                autocomplete="false"
                required
                maxlength="30"
                @keypress="alfaNumerico($event)"
                v-model="job"
              />
            </div>
          </v-row>
          <!--CLAVE DEL PUESTO-->
          <v-row>
            <div class="form-group mb-5">
              <label for="clave" class="ml-2 mr-3">Clave del puesto</label>
              <input
                type="text"
                name="clave"
                id="clave"
                placeholder="Obligatorio"
                class="ml-8 inputs"
                autocomplete="false"
                required
                maxlength="30"
                @keypress="alfaNumerico($event)"
                v-model="keyJob"
              />
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="aux()">Cancelar</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="validacion()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Aceptación de acciones-->
    <v-dialog v-model="advice" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Aviso</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 style="text-align: center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="red"
            text
            @click="
              advice = false;
              id = '';
            "
            >Cancelar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="deleteItem()">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Confirmación de datos-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="orange white--text">
          <span class="headline">Puesto</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-lg-center text-sm-center text-md-center">
            {{ respuesta }}
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="confirmation = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--DIALOG DE DATOS-->
    <v-dialog v-model="dialog" scrollable max-width="400px">
      <v-card>
        <v-card-title class="orange white--text"
          >Información de Puesto</v-card-title
        >
        <v-card-text id="info">
          <div class="form-group mt-5">
            <label>Empresa:</label>
            {{ jobSelect.empresaId }}
          </div>
          <div class="form-group">
            <label>Área a la que reporta:</label>
            {{ jobSelect.areaReportaId }}
          </div>
          <div class="form-group">
            <label>Puesto al que reporta:</label>
            {{ jobSelect.puestoReportaId }}
          </div>
          <div class="form-group">
            <label>Número de posiciones:</label>
            {{ jobSelect.numeroPosiciones }}
          </div>
          <div class="form-group">
            <label>Nombre de Puesto:</label>
            {{ jobSelect.nombrePuesto }}
          </div>
          <div class="form-group">
            <label>Clave de Puesto:</label>
            {{ jobSelect.clave }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cerrar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      dialog: false,
      selectedItem: null,
      niveles:[],
      headers: [
        {
          text: "N°",
          align: "center",
          value: "id",
        },
        {
          text: "Nombre de Puesto",
          align: "center",
          value: "nombrePuesto",
        },
        {
          text: "Editar  /   Ver  / Eliminar / Atención",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      jobs: [],
      enterprises: [],
      jobSelect: [],
      enterprise: localStorage.empresaIdGlobal,
      respuesta: "",
      advice: false,
      id: "",
      confirmation: false,
      job: "",
      jobReporta: 0,
      keyJob: "",
      empresa: 0,
      area: 0,
      posicion: 0,
      edit: false,
      vacio: false,
      areas: [],
    };
  },
  methods: {
    obtenerAreasTrabajos() {
      this.areas = [];
      this.show = true;
      axios
        .get(Server + "/areas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.areas.push({
                id: value.id,
                nombreArea: value.nombreArea,
              });
            }
          });
          //console.log("Areas",this.areas);
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });

      this.jobs = [];
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.jobs.push({
                id: value.id,
                nombrePuesto: value.nombrePuesto,
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          //console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    idEmpresa() {
      console.log("el id es" + this.enterprise);
      localStorage.idEmpresa = this.enterprise;
    },
    editItem(item) {
      this.obtenerAreasTrabajos();
      console.log("Ingreso a editar el item puesto con id" + item.id);
      console.log(item);
      this.id = item.id;
      this.show = true;
      axios
        .get(Server + "/puestos/" + item.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response.data);
          this.job = response.data.nombrePuesto;
          this.empresa = response.data.empresaId;
          this.posicion = response.data.numeroPosiciones;
          this.keyJob = response.data.clave;
          this.area = response.data.areaReportaId;
          if (response.data.puestoReportaId == null) {
            this.jobReporta = 0;
          } else {
            this.jobReporta = response.data.puestoReportaId;
          }
          this.show = false;
          this.edit = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    validacion: function () {
      this.errors = [];
      if (this.enterprise == "" || this.enterprise == 0) {
        this.errors.push("Se debe especificar la empresa.");
      }
      if (this.area == "" || this.keyJob == 0) {
        this.errors.push("Se debe especificar el área.");
      }
      if (this.jobReporta == "" || this.jobReporta == 0) {
        this.errors.push("Se debe especificar el puesto al que reporta.");
      }
      if (this.posicion == "" || this.posicion == 0) {
        this.errors.push("Se debe especificar el número de posición.");
      }
      if (this.job == "" || this.job == " ") {
        this.errors.push("Se debe especificar el nombre del puesto.");
      }
      if (this.keyJob == "" || this.keyJob == " ") {
        this.errors.push("Se debe especificar la clave de puesto.");
      }
      if (this.errors.length == 0) {
        this.edit = false;
        this.vacio = false;
        return this.editar();
      } else {
        this.vacio = true;
      }
    },

    obtenerEmpresa() {
      /* console.log("el id de empresa es" + this.enterprise);
      return (localStorage.idEmpresa = this.enterprise); */
      this.jobs = [];
      this.show = true;
      axios
        .get(Server + "/puestos", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log("Todos los puestos");
          console.log(response);
          var auxAreas = response.data;
          auxAreas.forEach((value, index) => {
            if (value.empresaId == this.enterprise) {
              this.jobs.push({
                empresa: value.empresaId,
                nombreEmpresa: value.empresa,
                areaReportaId: value.areaReportaId,
                areaReporta: value.areaReporta,
                puestoReporta: value.puestoReporta,
                puestoReportaId: value.puestoReportaId,
                id: value.id,
                numeroPosiciones: value.numeroPosiciones,
                nombrePuesto: value.nombrePuesto,
                clave: value.clave,
                puestoServicioCliente:value.puestoServicioCliente,
                esJefe:value.esJefe
              });
            }
          });
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    editar() {
      console.log("El id para la api es " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .put(
          Server + "/puestos/" + this.id,
          {
            Id: this.id,
            NombrePuesto: this.job,
            Clave: this.keyJob,
            AreaReportaId: this.area,
            PuestoReportaId: this.jobReporta,
            NumeroPosiciones: parseInt(this.posicion),
            EmpresaId: this.empresa,
          },
          {
            headers: {
              Authorization: localStorage.token,
            },
          }
        )
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.job = "";
          this.respuesta = "El elemento fue actualizado con éxito";
          this.obtenerEmpresa();
          this.show = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.job = "";
            this.respuesta = "El elemento no fue actualizado";
            this.confirmation = true;
          }
        });
    },
    /*
            temporal sirve para mostrar el nombre del dato a eliminar en la Alerta ayuda al usuario a no borrar el equivocado
            guarda solo el id del dato
            */
    temporal(item) {
      console.log("El id es: " + item.id);
      this.id = item.id;
      this.respuesta =
        "¿ Seguro que desea eliminar el elemento " + item.nombrePuesto + " ?";
      this.advice = true;
    },
    deleteItem() {
      this.advice = false;
      //console.log("Ingreso a eliminar el dato con id: " + this.id);
      this.respuesta = "";
      this.show = true;
      axios
        .delete(Server + "/puestos/" + this.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          //console.log(response);
          this.id = "";
          this.respuesta = "El elemento fue eliminado con éxito";
          this.obtenerEmpresa();
          this.show = false;
          this.confirmation = true;
          //this.listar();
          //this.obtenerEmpresa();
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          } else {
            this.id = "";
            this.respuesta = "El elemento no fue eliminado";
            this.confirmation = true;
          }
        });
    },
    viewItem(item) {
      this.obtenerAreasTrabajos();
      this.show = true;
      axios
        .get(Server + "/puestos/" + item.id, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.jobSelect = response.data;
          console.log(this.areas);
          var areaNombre = this.areas.find(
            (area) => area.id === this.jobSelect.areaReportaId
          );
          var empresa = this.enterprises.find(
            (empresa) => empresa.id === this.jobSelect.empresaId
          );
          this.jobSelect.areaReportaId = areaNombre.nombreArea;
          this.jobSelect.empresaId = empresa.razonSocial;
          if (this.jobSelect.puestoReportaId == null) {
            this.jobSelect.puestoReportaId = "Ninguno";
          } else {
            var puesto = this.jobs.find(
              (puesto) => puesto.id === this.jobSelect.puestoReportaId
            );
            this.jobSelect.puestoReportaId = puesto != null ? puesto.nombrePuesto : "";
          }
          var areaNombre = "";
          var empresa = "";
          var puesto = "";
          this.show = false;
          this.dialog = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    listar() {
      this.show = true;
      axios
        .get(Server + "/empresas", {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          console.log(response);
          this.enterprises = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
    alfaNumerico: function (evt) {
      var regex = new RegExp("^[a-zA-ZÀ-ÿ\u00f1\u00d10-9 ]+$"); // Letras y espacio
      var str = String.fromCharCode(!evt.charCode ? evt.which : evt.charCode);
      if (regex.test(str)) {
        return true;
      }
      evt.preventDefault();
      return false;
    },
    aux() {
      this.edit = false;
      this.empresa = 0;
      this.area = 0;
      this.jobReporta = 0;
      this.posicion = 0;
      this.job = "";
      this.keyJob = "";
      this.vacio = false;
    },
  },
  created() {
    this.listar();
    this.obtenerEmpresa();
    for (let index = 1; index <= 99; index++) {
      this.niveles.push({
        id: index,
        name: index.toString(),
      });
    }
  },
};
</script>